// MaintenanceTable.tsx

import React, { useState, useMemo, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Paper, TablePagination, Box, Tooltip, IconButton, CircularProgress, TableSortLabel
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { MaintenanceRecord, MaintenanceType, PageState } from '../hooks/Interfaces';
import MaintenanceFormDialog from '../forms/MaintenanceFormDialog';
import MaintenanceHistoryDialog from '../history/MaintenanceHistoryDialog';
import { SendMessageFunction } from '../../../../../websocket/hooks/types';
import { LibraryBooks } from '@mui/icons-material';

interface MaintenanceTableProps {
	maintenance: MaintenanceRecord[];
	sendMessage: SendMessageFunction;
	pageState: PageState
	type: 'Mold' | 'Machine';
	maintenanceTypes: MaintenanceType[];
}

const MaintenanceTable: React.FC<MaintenanceTableProps> = ({ maintenance, sendMessage, pageState, type, maintenanceTypes }) => {
	const [historyRecord, setHistoryRecord] = useState<{ id: number, type: 'Mold' | 'Machine', name: string } | null>(null);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);


	// Local state for sort field and direction
	const [sortField, setSortField] = useState<keyof MaintenanceRecord | null>(pageState.sort_by.field);
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(pageState.sort_by.direction || 'asc');

	// Debounced sendMessage to backend to optimize performance
	const debouncedSendMessage = useCallback(
		debounce((updatedPageState: PageState) => {
			sendMessage('update_page_state', { page_state: updatedPageState });
		}, 300), // 300ms debounce
		[sendMessage]
	);

	// Update local sort state when pageState changes (e.g., on initial load or tab visibility changes)
	useEffect(() => {
		setSortField(pageState.sort_by.field);
		setSortDirection(pageState.sort_by.direction || 'asc');
	}, [pageState.sort_by.field, pageState.sort_by.direction]);

	// Handle sort header clicks
	const handleSort = (field: keyof MaintenanceRecord) => {
		const isAsc = sortField === field && sortDirection === 'asc';
		const newSortDirection: 'asc' | 'desc' = isAsc ? 'desc' : 'asc';

		// Update local sort state immediately for instant UI feedback
		setSortField(field);
		setSortDirection(newSortDirection);

		pageState.sort_by.field = field
		pageState.sort_by.direction = newSortDirection

		debouncedSendMessage(pageState); // Send updated state to backend with debounce
	};

	// Memoize the maintenance data for performance
	const memoizedMaintenance = useMemo(() => {
		return maintenance.map((record) => ({
			...record,
			formattedDate: record.date ? formatDistanceToNow(parseISO(record.date), { addSuffix: true }) : 'N/A',
			localizedDate: record.date ? new Date(record.date).toLocaleString() : 'N/A',
			formattedPartsLogged: record.parts_logged !== null ? record.parts_logged.toLocaleString() : 'N/A'
		}));
	}, [maintenance]);

	// Handle pagination changes
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// Handle Maintenance History Dialog
	const handleOpenHistory = (id: number, type: 'Mold' | 'Machine', name: string) => {
		setHistoryRecord({ id, type, name });
	};

	const handleCloseHistory = () => {
		setHistoryRecord(null);
	};

	return (
		<>
			{maintenance.length === 0 ? (
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '400px' }}>
					<CircularProgress />
				</Box>
			) : (
				<>
					<TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'hidden', borderRadius: 3 }}>
						<Table stickyHeader aria-label={`${type.toLowerCase()}s table`}>
							<TableHead>
								<TableRow>
									<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center', fontWeight: 'bold' }}>
										{type} Name
									</TableCell>
									{ type == 'Mold' && 
									<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
										Description
									</TableCell>}
									<TableCell
										sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}
										sortDirection={sortField === 'date' ? sortDirection : false}
									>
										<TableSortLabel
											active={sortField === 'date'}
											direction={sortField === 'date' ? sortDirection : 'asc'}
											onClick={() => handleSort('date')}
										>
											Last Maintenance
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
										Maintenance Type
									</TableCell>
									{type === 'Mold' && (
										<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
											<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
												Parts Logged
												<Tooltip title="Parts Logged since last maintenance">
													<IconButton size="small" sx={{ marginLeft: 1 }}>
														<InfoIcon fontSize="inherit" />
													</IconButton>
												</Tooltip>
											</Box>
										</TableCell>
									)}
									<TableCell
										sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}
										sortDirection={sortField === 'performed_by' ? sortDirection : false}
									>
										<TableSortLabel
											active={sortField === 'performed_by'}
											direction={sortField === 'performed_by' ? sortDirection : 'asc'}
											onClick={() => handleSort('performed_by')}
										>
											Performed By
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{memoizedMaintenance.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record) => (
									<TableRow key={record.id} sx={{ backgroundColor: record.needs_maintenance ? 'rgba(255, 0, 0, 0.3)' : 'inherit' }}>
										<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center', fontWeight: 'bold' }}>{record.name}</TableCell>
										<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>{record.description}</TableCell>
										<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center', cursor: 'default' }}>
											<Tooltip title={record.localizedDate || 'N/A'}>
												<span>{record.formattedDate}</span>
											</Tooltip>
										</TableCell>
										<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>{record.last_maintenance_type}</TableCell>
										{type === 'Mold' && (
											<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>{record.formattedPartsLogged}</TableCell>
										)}
										<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>{record.performed_by || 'N/A'}</TableCell>
										<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
											<MaintenanceFormDialog
												selectedRecord={record}
												machineOrMold={type}
												sendMessage={sendMessage}
												maintenanceTypes={maintenanceTypes}
											/>
											<Tooltip title="View Maintenance History" arrow>
												<IconButton
													onClick={() => handleOpenHistory(record.id, type, record.name)}
													color='secondary'
													sx={{ border: 1, ml: 1 }}
												>
													<LibraryBooks />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<TablePagination
						component="div"
						count={maintenance.length}
						page={page}
						onPageChange={handleChangePage}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>

					{/* Maintenance History Dialog */}
					{historyRecord && (
						<MaintenanceHistoryDialog
							open={Boolean(historyRecord)}
							onClose={handleCloseHistory}
							id={historyRecord.id}
							type={historyRecord.type}
							name={historyRecord.name}
						/>
					)}
				</>
			)}
		</>
	);

};

export default MaintenanceTable;
